<template>
  <div  class="contrainer minWidth1000">
    <div class="contrainer-left">
      <custom-actions class="card" title="所属基站">
        <el-tree
          default-expand-all
          :expand-on-click-node="false"
          :data="deptList"
          :highlight-current="true"
          :props="defaultProps"
          @node-click="handleNodeClick">
          <template v-slot="{ data }">
            <div class="org-item">
              <span>{{data.deptName}}</span>
              <img :class="{ visible: !!data.msg }" src="@/assets/gaojing.png" alt="">
            </div>
          </template>
        </el-tree>
      </custom-actions>
    </div>
    <div class="contrainer-right">
      <custom-actions>
        <template #header>
          <el-button v-if="permission.add" type="primary" icon="el-icon-plus" @click="showForm(null)">新增</el-button>
        </template>
        <el-form inline label-width="80px">
          <el-form-item label="账号名称">
            <el-input v-model="searchForm.loginId" placeholder="账号名称"/>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="searchForm.name" placeholder="姓名"/>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="searchForm.mobile" placeholder="手机号"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </custom-actions>

      <el-card shadow="never" class="my-table" :body-style="{ padding: '15px' }">
        <el-table stripe size="mini" border v-loading="loading" :data="tableData">
          <el-table-column min-width="120" label="登录名" prop="loginId"></el-table-column>
          <el-table-column min-width="120" label="姓名" prop="personName"></el-table-column>
          <el-table-column min-width="120" label="手机号" prop="mobile"></el-table-column>
          <el-table-column min-width="120" label="角色名称" prop="roleName"></el-table-column>
          <el-table-column min-width="120" label="基站名称" prop="deptName"></el-table-column>
          <el-table-column min-width="120" label="用户状态" prop="isDel">
            <template v-slot="{ row }">
              <el-tag type="primary" v-if="row.isDel === 0">启用</el-tag>
              <el-tag type="danger" v-else>禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column min-width="160" label="操作" fixed="right">
            <template v-slot="{ row }">
              <el-button v-if="permission.edit" type="text" @click="$router.push('/system/account/edit?userId=' + row.userId)">编辑</el-button>
              <el-button v-if="permission.edit" type="text" @click="resetPassword(row.userId)">重置密码</el-button>
              <el-button v-if="permission.del && row.isAdmin !== '1'" type="text" @click="deleteRow(row)" class="del-btn">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="mt10"
          :current-page.sync="page"
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="loadData">
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
import CustomActions from '../../components/CustomActions.vue'
export default {
  components: { CustomActions },
  name: 'AccountManage',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      searchForm: {
        deptId: null,
        loginId: null,
        name: null,
        mobile: null
      },
      deptList: [],
      defaultProps: {
        children: 'childNode',
        label: 'deptName',
        value: 'deptId'
      }
    }
  },
  created () {
    this.loadData()
    this.getDept()
  },
  computed: {
    permission () {
      return this.$store.state.permission
    }
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.system.selectSysUserByPage, {
        params: {
          page: this.page,
          ...this.searchForm
        }
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    handleNodeClick (data) {
      this.searchForm.deptId = data.deptId
      this.loadData()
    },
    getDept () {
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        if (res) {
          this.deptList = res
        }
      })
    },
    showForm () {
      this.$router.push('/system/account/add')
    },
    resetPassword (userId) {
      this.$confirm('重置后的密码为用户手机号后六位, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.system.resetPassword, {
          userId
        }).then(() => {
          this.loadData()
        })
      })
    },
    deleteRow (row) {
      console.log('row', row)
      // eslint-disable-next-line eqeqeq
      this.$confirm('确认删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.system.deleteSysUser, {
          userId: row.userId
        }).then(() => {
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.el-select {
  width: 100%;
}

.my-table {
  margin-top: 15px;
}

.el-tree {
  margin: -10px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}

.contrainer {
  display: flex;
  .contrainer-left {
    width: 260px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);

    .card {
      height: 100%;
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
</style>
